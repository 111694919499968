export function setName(name) {
  if (typeof window !== 'undefined') {
    if (window.top !== window && window.frameElement) {
      const i = parseInt(window.frameElement.getAttribute('data-index'), 10);

      window.top.postMessage({
          source: 'west-space',
          event: 'setName',
          payload: [
            parseInt(i, 10),
            name,
          ],
        },
        '*',
      );
    }
  }
}
