import React from 'react';
import { css } from '@emotion/react';

export default function Pill({
  className,
  children,
  color,
}) {
  function getColor(color) {
    if (!color) { return ''; }
    return (parseInt(color.replace('#', ''), 16) > 0xffffff / 2) ? '#000' : '#fff';
  }

  return (
    <div
      className={`type--30 type--uppercase ${className}`}
      css={css`
        display: inline-block;
        color: ${getColor(color)};
        background-color: ${color};
        padding: 10px 20px;
        border-radius: 45px;
        box-shadow: var(--shadow);
      `}
    >
      {children}
    </div>
  );
}
