import React from 'react';
import { Link as GatsbyLink } from 'gatsby';

export default function Link({ children, to, activeClassName, partiallyActive, oppositeFrame, ...props }) {
  const internal = /^\/(?!\/)/.test(to);

  if (internal) {
    return (
      <GatsbyLink
        to={to}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        onClick={(e) => {
          e.preventDefault();

          const i = parseInt(window.frameElement.getAttribute('data-index'), 10);

          if (oppositeFrame && window.top !== window && window.top.innerWidth > 1024) {
            window.top.postMessage({
                source: 'west-space',
                event: 'setFrame',
                payload: [
                  (i) ? 0 : 1,
                  to
                ],
              },
              '*'
            );
          } else {
            window.top.postMessage({
                source: 'west-space',
                event: 'setFrame',
                payload: [
                  i,
                  to
                ],
              },
              '*'
            );
          }
        }}
        {...props}
      >
        {children}
      </GatsbyLink>
    );
  }

  return (
    <a href={to} target="_blank" rel="noreferrer" {...props}>
      {children}
    </a>
  );
}
